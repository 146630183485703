import request from "@/api/service";

const api = {
   marketingSalesDetail: '/order/mallMarketing/marketingSalesDetail',
   marketingSalesDetailInfo: '/order/mallMarketing/marketingSalesDetailInfo',
   marketingSalesDetailExcel: '/order/mallMarketing/marketingSalesDetailExcel'
}

export function getMarketingSalesDetail(query, params) {
   return request({
      url: api.marketingSalesDetail + query,
      method: 'POST',
      data: params
   })
}

export function getMarketingSalesDetailExcel(params) {
   return request({
      url: api.marketingSalesDetailExcel,
      method: 'GET',
      params,
      responseType: 'blob'
   })
}

export function getMarketingSalesDetailInfo(query, params) {
   return request({
      url: api.marketingSalesDetailInfo + query,
      method: 'POST',
      data: params
   })
}
