<template>
  <div class="cont">
    <Breadcrumb :crumbs="crumbs"/>
    <div class="content-box">
      <el-row class="search-box">
        <el-row class="search-row">
          <el-row class="search-item" v-if="accountType === 'PLATFORM'">
            <label>选择酒店：</label>
            <el-select v-model="hotelId" placeholder="请选择">
              <el-option
                  v-for="item in hotelOpt"
                  :key="item.id"
                  :label="item.hotelName"
                  :value="item.id">
              </el-option>
            </el-select>
          </el-row>
          <el-row class="search-item">
            <label>营业时间：</label>
            <date-packer :datePacker="dateRange" @setDatePacker="getDatePacker" format="yyyy-MM-dd"></date-packer>
          </el-row>
          <el-button class="bg-gradient" type="primary" @click="handleQuery(true)">搜索</el-button>
          <el-button type="primary" plain @click="handleQuery(false)">重置</el-button>
          <el-button class="bg-gradient" type="primary" @click="getMarketingSalesDetailExcel">导出</el-button>
        </el-row>
      </el-row>
      <el-row class="table-box">
        <el-table v-loading="loading" :data="tableData" :stripe="true" border fit>
          <el-table-column label="序号">
            <template slot-scope="scope">{{ scope.$index + 1 }}</template>
          </el-table-column>
          <el-table-column prop="date" label="日期"></el-table-column>
          <el-table-column prop="totalAmount" label="销售额"></el-table-column>
          <el-table-column prop="orderNumber" label="订单数量"></el-table-column>
          <el-table-column prop="productNum" label="商品数"></el-table-column>
          <el-table-column prop="productTypeNum" label="商品种类"></el-table-column>
          <el-table-column prop="userNumber" label="购买用户"></el-table-column>
          <el-table-column label="操作">
            <template slot-scope="scope">
              <el-button @click="handleDetail(scope.row.date)" type="text">详情</el-button>
            </template>
          </el-table-column>
        </el-table>
        <pagination :total="total" :page-size="limit" @handleSizeChangeSub="changePageNum" @handleCurrentChangeSub="handleCurrPage"/>
      </el-row>
    </div>
  </div>
</template>
<script>
import { selectHotel } from "@/api/shop/statement/salesOverview";
import { getMarketingSalesDetail, getMarketingSalesDetailExcel } from '@/api/shop/statement/salesDetails';
import { exportFile } from '@/common/js/common';
import { mapState } from 'vuex';
export default {
  data () {
    return {
      crumbs: new Map([
        ['特惠商城'], ['对账报表'], ['销售明细']
      ]),
      loading: true,
      hotelOpt: [],
      hotelId: '',
      limit: 0,
      page: 1,
      total: 0,
      accountType: '',
      dateRange: [],
      tableData: []
    }
  },
  computed: {
    ...mapState(['hotelInfo'])
  },
  mounted() {
    this.limit = sessionStorage.getItem('pageSize') * 1;
    this.accountType = JSON.parse(sessionStorage.getItem('userInfo') || '{}').accountType;
    if (this.accountType === 'PLATFORM') this.getHotelOpt()
    else {
      this.hotelId = this.hotelInfo.id
      this.getMarketingSalesDetail()
    }
  },
  methods: {
    // 获取销售明细
    getMarketingSalesDetail() {
      const query = `?limit=${ this.limit }&page=${ this.page }`,
            params = {
              hotelId: this.hotelId,
              beginDateStr: this.dateRange[0],
              endDateStr: this.dateRange[1]
            };
      getMarketingSalesDetail(query, params).then(({ success, records, total }) => {
        if (success) {
          this.loading = false
          this.total = total
          this.tableData = records
        }
      })
    },
    // 获取酒店下拉
    getHotelOpt() {
      selectHotel({}).then(({ success, records }) => {
        if (success) {
          this.hotelOpt = records.map(item => ({ id: item.id, hotelName: item.hotelName }))
          if (this.hotelOpt.length > 0) {
            this.hotelId = this.hotelOpt[0].id
            this.getMarketingSalesDetail()
          }
        }
      })
    },
    // 获取订单时间
    getDatePacker(val) {
      this.dateRange = val
    },
    // 查询订单
    handleQuery(bool) {
      this.page = 1
      if (bool) return this.getMarketingSalesDetail()
      this.dateRange = []
    },
    // 导出销售表格
    getMarketingSalesDetailExcel() {
      const params = {
        hotelId: this.hotelId,
        beginDateStr: this.dateRange[0],
        endDateStr: this.dateRange[1]
      };
      getMarketingSalesDetailExcel(params).then(res => {
        exportFile(res, '销售明细报表')
      })
    },
    // 改变每页数
    changePageNum(num) {
      this.limit = num
      this.getMarketingSalesDetail()
    },
    // 改变当前页
    handleCurrPage(num) {
      this.page = num
      this.getMarketingSalesDetail()
    },
    // 销售明细详情
    handleDetail(date) {
      this.$router.push({
        path: '/reconciliation_statement/sales_details/detail',
        query: { date, hotelId: this.hotelId } })
    }
  }
}
</script>
